<template>
  <div id="input-group-1" class="mb-2" label-for="input-warehouse">
    <label v-if="fullwidth" class="text-dark">{{
      $t('return-location')
    }}</label>
    <b-form-select
      id="input-warehouse"
      :size="size"
      :style="style"
      :disabled="formDisabled"
      v-model="returnWarehouse"
      :options="warehouses"
    ></b-form-select>
  </div>
</template>

<script>
import { updateCart } from '@/services/CartService'

export default {
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      returnWarehouse: null,
      formDisabled: false,
      mutationProblems: {},
      retrievalDate: '',
      returnDate: '',
      previousReturnWarhouse: null,
      loading: false
    }
  },
  computed: {
    warehouses: function() {
      return [
        {
          value: '',
          text: this.$t('choose-retrieval-location'),
          disabled: true
        },
        { value: 'Amsterdam', text: 'Amsterdam' },
        { value: 'Terneuzen', text: 'Terneuzen' },
        {
          value: 'Middelburg',
          text: 'Middelburg'
        },
        {
          value: 'Warehouse',
          text: 'Warehouse'
        }
      ].concat(this.$store.state.temporaryWarehouses)
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    },

    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },

    size: function() {
      if (this.fullwidth) return 'md'
      return 'sm'
    },
    style: function() {
      if (this.fullwidth) return ''
      return 'max-width: 300px; padding-bottom: 2px !important; padding-top: 2px !important;'
    },
    activeReturnWarehouse: function() {
      return this.$store.state.activeReturnWarehouse
    }
  },
  watch: {
    returnWarehouse: async function(returnWarehouse) {
      if (returnWarehouse === this.activeReturnWarehouse) return
      await updateCart({
        warehouseID: this.activeWarehouse,
        returnWarehouseID: returnWarehouse,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate
      })
    }
  },
  created: function() {
    this.returnWarehouse = this.activeWarehouse
  },
  methods: {
    changeWarehouse: function(warehouseID) {
      this.$store.commit('setActiveWarehouseID', { warehouseID })
    }
  }
}
</script>

<style></style>
