<template>
  <div
    :class="`${classes}`"
    v-if="activeWarehouse !== null && startDate !== null && endDate !== null"
  >
    <icon-spinner v-if="loading" class="loader" />

    <warehouse-selector-form
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      v-show="false"
      class="ml-auto"
    />

    <warehouse-return-selector
      v-if="cartPage"
      v-show="false"
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      class="ml-auto"
    />
    <rental-period-selector
      v-if="(endDate || startDate) && activeWarehouse"
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      class="ml-auto "
    />
  </div>
</template>

<script>
import WarehouseSelectorForm from '../global/WarehouseSelectorForm.vue'
import RentalPeriodSelector from '../global/RentalPeriodSelector'
import WarehouseReturnSelector from '../global/WarehouseReturnSelectorForm'
import IconSpinner from '@/components/icons/IconSpinner'
export default {
  components: {
    IconSpinner,
    WarehouseSelectorForm,
    RentalPeriodSelector,
    WarehouseReturnSelector
  },
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    loading: function() {
      return this.$store.state.loadingDateWarehouse
    },
    cartPage: function() {
      return this.$route.name === 'cart'
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    style: function() {
      let style = ''
      if (!this.fullwidth) {
        style = style + ' max-width: 300px;'
      }

      return style
    },
    tempWarehouse: function() {
      return this.$route.query.location
    },
    classes: function() {
      if (this.loading) {
        if (this.fullwidth) return 'loading'
        return 'justify-content-end rental-info loading'
      }
      if (this.fullwidth) return ''

      if (this.loading) {
        return 'justify-content-end rental-info loading'
      }

      return 'justify-content-end rental-info'
    }
  }
}
</script>

<style lang="scss">
.spinner {
  position: absolute;
  top: 8px;
  right: 20px;
  //right: 0px;
}

.loading {
  pointer-events: none;
}
@media (min-width: 992px) {
  .rental-info {
    display: block !important;
  }
}
.rental-info {
  //display: none;
  .datepicker {
    input.form-control.datepicker-input {
      height: 31px;
    }
  }
}

.loader {
  position: absolute;
  right: 20px;
}
</style>
