<template>
  <div id="input-group-1" class="mb-2" label-for="input-warehouse">
    <label v-if="fullwidth" class="text-dark">{{
      $t('retrieval-location')
    }}</label>
    <b-form-select
      id="input-warehouse"
      :disabled="formDisabled"
      v-model="warehouse"
      :options="warehouses"
      text-field="WarehouseID"
      value-field="WarehouseID"
    ></b-form-select>
    <b-modal
      id="mutation-problems"
      :visible="showMutationProblems"
      :hide-header="true"
      :hide-footer="true"
    >
      <h5>
        <span class="text-danger">
          <icon-exclamation-circle />
        </span>
        <span class="text-primary">&nbsp;{{ $t('Warning') }}!</span>
      </h5>
      <p class="my-4">
        {{ $t('cart.warning-making-changes') }}
      </p>
      <ul>
        <li v-for="item in mutationProblems" :key="item.ID">
          {{ item.Description }}
          <small class="text-danger">{{ item.InvalidCartItemReason }}</small>
        </li>
      </ul>

      <div class="text-center">
        <hr />
        <b-button @click="updateCart" class="text-center" variant="primary">{{
          $t('continue-and-change-location')
        }}</b-button>
        <b-button @click="cancel" variant="link">
          {{ $t('cart.cancel-items-not-available') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { cartMutationCheck, updateCart, getCart } from '@/services/CartService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import IconExclamationCircle from '@/components/icons/IconExclamationCircle'
dayjs.extend(customParseFormat)
import { getWarehouses } from '@/services/WarehouseService'

export default {
  components: { IconExclamationCircle },
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      warehouse: null,
      returnWarehouse: null,
      formDisabled: false,
      mutationProblems: {},
      retrievalDate: '',
      returnDate: '',
      previousWarehouse: null,
      loading: false,
      showMutationProblems: false
    }
  },
  computed: {
    warehouses: function() {
      return this.$store.state.warehouses
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    activeReturnWarehouse: function() {
      return this.$store.getters.activeReturnWarehouse
    },

    categoryID: function() {
      return this.$route.params.categoryID
    },

    tempWarehouse: function() {
      return this.$route.query.location
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    }
  },
  watch: {
    tempWarehouse: async function(tempWarehouse) {
      if (tempWarehouse) {
        const warehouse = tempWarehouse
        const warehouses = [
          {
            value: warehouse,
            text: warehouse,
            date: dayjs()
          }
        ]

        this.$store.commit('setTemporaryWarehouses', { warehouses })
        this.warehouse = tempWarehouse
        this.changeWarehouse(warehouse)
      }
    },
    warehouse: async function(warehouse) {
      if (this.loading) return

      if (
        warehouse === this.$store.state.cartCache?.WarehouseID ||
        warehouse === ''
      )
        return

      this.$store.commit('setLoadingDateWarehouse', { value: true })
      this.loading = true

      await getCart({ warehouse })

      await this.attemptCartUpdate()

      this.changeWarehouse(warehouse)

      this.$store.commit('setLoadingDateWarehouse', { value: false })
      this.loading = false
    },
    endDate: async function(endDate) {
      if (this.loading) return
      if (
        endDate === this.$store.state.cartCache.DateTimeBusinessEnd ||
        this.$store.state.cartCache.WarehouseID === ''
      )
        return

      this.loading = true
      this.$store.commit('setLoadingDateWarehouse', { value: true })

      await this.attemptCartUpdate()

      this.$store.commit('setLoadingDateWarehouse', { value: false })
      this.loading = false
    },
    mutationProblems: function() {
      if (this.mutationProblems.Message) return
      this.$store.commit('setMutationProblems', {
        mutationProblems: this.mutationProblems.filter(
          mutationProblem => mutationProblem.ItemID !== 'Borg'
        )
      })
      if (this.mutationProblems.length > 0) {
        this.showMutationProblems = true
      }
    }
  },
  async created() {
    this.warehouse = this.activeWarehouse
    this.$store.commit('setLoadingDateWarehouse', { value: false })

    if (
      this.$store.state.temporaryWarehouses.length &&
      dayjs(this.$store.state.temporaryWarehouses[0].date).isBefore(
        dayjs().subtract(1, 'days')
      )
    ) {
      this.$store.commit('setTemporaryWarehouses', { warehouses: [] })
    }

    const warehouses = await getWarehouses()
    this.$store.commit('setWarehouses', { warehouses: warehouses.Collection })
  },
  methods: {
    cancel: async function() {
      this.showMutationProblems = false
      this.warehouse = this.$store.getters.cartCache.WarehouseID

      this.$store.commit('setActiveWarehouseID', {
        warehouseID: this.$store.getters.cartCache.WarehouseID
      })

      this.$store.commit('setDateTimeBusinessStart', {
        date: dayjs(this.$store.getters.cartCache.DateTimeBusinessStart).format(
          'DD/MM/YYYY'
        )
      })

      this.$store.commit('setDateTimeBusinessEnd', {
        date: dayjs(this.$store.getters.cartCache.DateTimeBusinessEnd).format(
          'DD/MM/YYYY'
        )
      })

      await updateCart({
        warehouseID: this.warehouse,
        returnWarehouseID: this.activeReturnWarehouse,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate
      })
    },

    changeWarehouse: function(warehouseID) {
      this.$store.commit('setActiveWarehouseID', { warehouseID })
    },
    attemptCartUpdate: async function() {
      await this.updateCartMutationProblems()

      if (this.mutationProblems.length < 1) {
        await updateCart({
          warehouseID: this.warehouse,
          returnWarehouseID: this.warehouse,
          dateTimeBusinessStart: this.startDate,
          dateTimeBusinessEnd: this.endDate
        })
      }
    },
    updateCartMutationProblems: async function() {
      if (this.startDate === 'Invalid Date') return
      if (!this.startDate) return

      this.formDisabled = true
      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate
      })
      if (this.mutationProblems.Message) return
      this.mutationProblems = this.mutationProblems.filter(
        mutationProblem => mutationProblem.ItemID !== 'Borg'
      )
      this.formDisabled = false
    },
    async updateCart(event) {
      event.preventDefault()
      this.cartProcessing = true

      if (
        dayjs(this.startDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.endDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          returnWarehouseID: this.warehouse,
          dateTimeBusinessStart: this.startDate,
          dateTimeBusinessEnd: this.endDate
        })
      }

      this.showMutationProblems = false

      await this.refreshCart()

      this.cartProcessing = false
      this.isLocationOrDateChanged = true
    }
  }
}
</script>

<style></style>
