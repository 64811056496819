import { axiosCreate } from './Api'
import store from '../state/store'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

async function getClosedDays() {
  const result = await axiosCreate.get('closingDays', {
    params: {
      warehouse: store.state.activeWarehouse,
      dateFrom: dayjs().format('YYYY-MM-DD'),
      dateTo: dayjs()
        .add('180', 'days')
        .format('YYYY-MM-DD')
    },
    headers: { authorization: store.getters.token }
  })

  return result.data
}

async function getWarehouses() {
  const result = await axiosCreate.get('shop/warehouse', {
    headers: { authorization: store.getters.token }
  })

  return result.data
}

module.exports = {
  getClosedDays,
  getWarehouses
}
